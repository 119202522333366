import { Router } from '@reach/router';
import React from 'react';
import Confirmation from '../../../components/Checkout/Confirmation';
import ContextProvider from '../../../components/ContextProvider';
import PrivateRoute from '../../../components/PrivateRoute';

const ConfirmationPageEnAu = () => {
  return (
    <ContextProvider localeCode="en-AU">
      <Router>
        <PrivateRoute
          path="/en-AU/checkout/confirmation/:checkoutId"
          component={Confirmation}
        />
      </Router>
    </ContextProvider>
  );
};

export default ConfirmationPageEnAu;
