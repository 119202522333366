import { useStaticQuery, graphql } from 'gatsby';

export class ConfirmationPageContent {
  constructor(
    public metaTitle: string,
    public metaDescription: string,
    public errorGeneric: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public successUpdate: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public successTrialing: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public successNewSubscription: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public errorPaymentFail: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public errorRequiresAction: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public errorPastDue: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public errorSystemFail: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public errorNoConfirmationRecord: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public errorOrderNotFound: {
      title: string;
      summary: string;
      description: {
        childMarkdownRemark: {
          rawMarkdownBody: string;
        };
      };
    },
    public processingLabel: string,
    public seeYourInvoicesLabel: string,
    public backToCheckoutLabel: string,
    public accountLabel: string,
    public viewInvoiceLabel: string,
  ) {
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
    this.errorGeneric = errorGeneric;
    this.successUpdate = successUpdate;
    this.successTrialing = successTrialing;
    this.successNewSubscription = successNewSubscription;
    this.errorPaymentFail = errorPaymentFail;
    this.errorRequiresAction = errorRequiresAction;
    this.errorPastDue = errorPastDue;
    this.errorSystemFail = errorSystemFail;
    this.errorNoConfirmationRecord = errorNoConfirmationRecord;
    this.errorOrderNotFound = errorOrderNotFound;
    this.processingLabel = processingLabel;
    this.seeYourInvoicesLabel = seeYourInvoicesLabel;
    this.backToCheckoutLabel = backToCheckoutLabel;
    this.accountLabel = accountLabel;
    this.viewInvoiceLabel = viewInvoiceLabel;
  }
}

export const useConfirmationPageContent = () => {
  const data = useStaticQuery(graphql`
    query ConfirmationPageQuery {
      enUs: contentfulSiteData(node_locale: { eq: "en-US" }) {
        checkoutFlow {
          confirmationMetaTitle
          confirmationMetaDescription
          confirmationErrorGeneric {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationSuccessUpdate {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationSuccessTrialing {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationSuccessNewSubscription {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorPaymentFail {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorRequiresAction {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorPastDue {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorSystemFail {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorNoConfirmationRecord {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorOrderNotFound {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationProcessingLabel
          confirmationSeeYourInvoicesLabel
          confirmationBackToCheckoutLabel
          confirmationAccountLabel
          confirmationViewInvoiceLabel
        }
      }
      enAu: contentfulSiteData(node_locale: { eq: "en-AU" }) {
        checkoutFlow {
          confirmationMetaTitle
          confirmationMetaDescription
          confirmationErrorGeneric {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationSuccessUpdate {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationSuccessTrialing {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationSuccessNewSubscription {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorPaymentFail {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorRequiresAction {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorPastDue {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorSystemFail {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorNoConfirmationRecord {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationErrorOrderNotFound {
            title
            summary
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          confirmationProcessingLabel
          confirmationSeeYourInvoicesLabel
          confirmationBackToCheckoutLabel
          confirmationAccountLabel
          confirmationViewInvoiceLabel
        }
      }
    }
  `);

  const enUs = new ConfirmationPageContent(
    data.enUs.checkoutFlow.confirmationMetaTitle,
    data.enUs.checkoutFlow.confirmationMetaDescription,
    data.enUs.checkoutFlow.confirmationErrorGeneric,
    data.enUs.checkoutFlow.confirmationSuccessUpdate,
    data.enUs.checkoutFlow.confirmationSuccessTrialing,
    data.enUs.checkoutFlow.confirmationSuccessNewSubscription,
    data.enUs.checkoutFlow.confirmationErrorPaymentFail,
    data.enUs.checkoutFlow.confirmationErrorRequiresAction,
    data.enUs.checkoutFlow.confirmationErrorPastDue,
    data.enUs.checkoutFlow.confirmationErrorSystemFail,
    data.enUs.checkoutFlow.confirmationErrorNoConfirmationRecord,
    data.enUs.checkoutFlow.confirmationErrorOrderNotFound,
    data.enUs.checkoutFlow.confirmationProcessingLabel,
    data.enUs.checkoutFlow.confirmationSeeYourInvoicesLabel,
    data.enUs.checkoutFlow.confirmationBackToCheckoutLabel,
    data.enUs.checkoutFlow.confirmationAccountLabel,
    data.enUs.checkoutFlow.confirmationViewInvoiceLabel,
  );

  const enAu = new ConfirmationPageContent(
    data.enAu.checkoutFlow.confirmationMetaTitle,
    data.enAu.checkoutFlow.confirmationMetaDescription,
    data.enAu.checkoutFlow.confirmationErrorGeneric,
    data.enAu.checkoutFlow.confirmationSuccessUpdate,
    data.enAu.checkoutFlow.confirmationSuccessTrialing,
    data.enAu.checkoutFlow.confirmationSuccessNewSubscription,
    data.enAu.checkoutFlow.confirmationErrorPaymentFail,
    data.enAu.checkoutFlow.confirmationErrorRequiresAction,
    data.enAu.checkoutFlow.confirmationErrorPastDue,
    data.enAu.checkoutFlow.confirmationErrorSystemFail,
    data.enAu.checkoutFlow.confirmationErrorNoConfirmationRecord,
    data.enAu.checkoutFlow.confirmationErrorOrderNotFound,
    data.enAu.checkoutFlow.confirmationProcessingLabel,
    data.enAu.checkoutFlow.confirmationSeeYourInvoicesLabel,
    data.enAu.checkoutFlow.confirmationBackToCheckoutLabel,
    data.enAu.checkoutFlow.confirmationAccountLabel,
    data.enAu.checkoutFlow.confirmationViewInvoiceLabel,
  );

  const confirmationPageContent = {
    enUs: enUs,
    enAu: enAu,
  };

  return confirmationPageContent;
};
