import * as Sentry from '@sentry/gatsby';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Button, Flex } from 'rebass/styled-components';
import Stripe from 'stripe';
import { useConfirmationPageContent } from '../../hooks/useConfirmationPageContent';
import { getUser } from '../../utils/Auth';
import { firestore } from '../../utils/Firebase';
import { LocaleContext } from '../ContextProvider';
import { Layout } from '../Layout';
import LoadingOverlay from '../LoadingOverlay';
import markdownRenderer from '../MarkdownRenderer';
import Section from '../Section';
import TextLibrary from '../TextLibrary';

const Confirmation = (props: {
  uri: string;
  checkoutId: string;
}): JSX.Element => {
  const stripePromise = loadStripe(process.env.STRIPE_API_KEY || '');
  const localeData = useContext(LocaleContext);
  const { locale, rootPath } = localeData;
  const { uri, checkoutId } = props;

  const contentData = useConfirmationPageContent();
  let confirmationPageContent = contentData.enUs;
  if (locale === 'en-AU') {
    confirmationPageContent = contentData.enAu;
  }

  const {
    metaTitle,
    metaDescription,
    errorGeneric,
    errorNoConfirmationRecord,
    errorOrderNotFound,
    errorPastDue,
    errorPaymentFail,
    errorRequiresAction,
    errorSystemFail,
    successNewSubscription,
    successTrialing,
    successUpdate,
    processingLabel,
    seeYourInvoicesLabel,
    backToCheckoutLabel,
    accountLabel,
    viewInvoiceLabel,
  } = confirmationPageContent;

  const [content, setContent] = useState({
    processing: true,
    title: '<title>',
    summary: '<summary>',
    description: '<description>',
    callToAction: (
      <Button
        onClick={() => navigate(`${rootPath}account`)}
        mt={[1]}
        width="100%"
        variant="primary">
        {accountLabel}
      </Button>
    ),
  });

  if (!checkoutId) {
    // No Checkout Id defined, redirect to the pricing page.
    navigate(`${rootPath}pricing`, { state: { uri } });
    return <></>;
  }

  const user = getUser();
  const { uid } = user;

  const CheckoutButton = () => (
    <Button
      onClick={() => navigate(`${rootPath}checkout`)}
      mt={[1]}
      width="100%"
      variant="secondary">
      {backToCheckoutLabel}
    </Button>
  );

  const isError = () => {
    setContent({
      ...content,
      processing: false,
      title: errorGeneric.title,
      summary: errorGeneric.summary,
      description: errorGeneric.description.childMarkdownRemark.rawMarkdownBody,
      callToAction: (
        <>
          <CheckoutButton />
          {content.callToAction}
        </>
      ),
    });
  };

  // Handles state for a credit card that requires further authentication
  const RequiresAction = ({ clientSecret }: { clientSecret: string }) => {
    const stripe = useStripe();
    const [confirmModal, setConfirmModal] = useState(<></>);

    useEffect(() => {
      const triggerModal = async () => {
        if (stripe) {
          const { paymentIntent, error } =
            stripe.confirmCardPayment(clientSecret);
        }
      };

      triggerModal();
    }, [stripe]);

    return confirmModal;
  };

  useEffect(() => {
    let isCancelled = false;

    try {
      const ref = doc(
        firestore,
        'billingTriggers',
        uid,
        'subscriptions',
        checkoutId,
      );
      const unsub = onSnapshot(
        ref,
        async (checkoutSnapshot) => {
          if (checkoutSnapshot.exists() && checkoutSnapshot.data()) {
            const checkoutData = checkoutSnapshot.data();

            // The firebase function has completed so retrieve
            // the confirmation record from the read-only table billing
            if (checkoutData && !checkoutData.processing) {
              try {
                const subsRef = doc(
                  firestore,
                  'billing',
                  uid,
                  'subscriptionHistory',
                  checkoutId,
                );
                const confirmation = await getDoc(subsRef);

                if (
                  !isCancelled &&
                  confirmation.exists() &&
                  confirmation.data()
                ) {
                  const confirmationData = confirmation.data();

                  if (confirmationData) {
                    const subscription: Stripe.Subscription =
                      confirmationData.subscription;
                    const subscriptionType: string =
                      confirmationData.subscriptionType;

                    switch (subscription.status) {
                      // TODO: handle "unpaid" state. We turned this off in
                      // Stripe settings but should enable and support
                      // again in the future.
                      // https://stripe.com/docs/billing/subscriptions/overview#unpaid-subscriptions
                      // case 'unpaid':
                      //   break;
                      case 'trialing':
                      // Subscription went through successfully, payment verified and trial has begun.
                      // eslint-disable-next-line no-fallthrough
                      case 'active':
                        // Subscription went through successfully and is now active
                        if (subscriptionType === 'update') {
                          // This was a case where it was an update of an existing subscription
                          // there's a possibility that no invoice is associated
                          setContent({
                            ...content,
                            processing: false,
                            title: successUpdate.title,
                            summary: successUpdate.summary,
                            description:
                              successUpdate.description.childMarkdownRemark
                                .rawMarkdownBody,
                          });
                        } else {
                          let hostedInvoiceUrl: string | undefined;
                          let customerEmail: string | undefined;
                          const {
                            subscription: { latest_invoice: latestInvoice },
                          } = confirmationData;
                          if (latestInvoice) {
                            hostedInvoiceUrl = latestInvoice.hosted_invoice_url;
                            customerEmail = latestInvoice.customer_email;
                          }

                          let title;
                          let summary;
                          let description;
                          if (subscription.status === 'trialing') {
                            title = successTrialing.title;
                            summary = successTrialing.summary;
                            description =
                              successTrialing.description.childMarkdownRemark
                                .rawMarkdownBody;
                          } else {
                            title = successNewSubscription.title;
                            summary = successNewSubscription.summary;
                            description =
                              successNewSubscription.description
                                .childMarkdownRemark.rawMarkdownBody;
                          }

                          setContent({
                            ...content,
                            processing: false,
                            title: title,
                            summary: summary,
                            description: `${description} ${customerEmail}`,
                            callToAction: (
                              <>
                                {hostedInvoiceUrl && (
                                  <Button
                                    onClick={() =>
                                      window.open(hostedInvoiceUrl)
                                    }
                                    mt={[1]}
                                    variant="secondary"
                                    width="100%">
                                    {viewInvoiceLabel}
                                  </Button>
                                )}
                                {content.callToAction}
                              </>
                            ),
                          });
                        }
                        break;

                      case 'incomplete':
                        // INCOMPLETE
                        // Subscription failed due to payment issue.
                        // eslint-disable-next-line no-case-declarations
                        let invoiceStatus: Stripe.Invoice.Status | undefined;
                        // eslint-disable-next-line no-case-declarations
                        let latestInvoice: Stripe.Invoice | string | undefined;
                        // eslint-disable-next-line no-case-declarations
                        let paymentIntentStatus:
                          | Stripe.PaymentIntent.Status
                          | undefined;

                        if (subscription.latest_invoice) {
                          latestInvoice = subscription.latest_invoice;
                          if (typeof latestInvoice !== 'string') {
                            if (latestInvoice.status) {
                              invoiceStatus = latestInvoice.status;
                            }

                            if (
                              latestInvoice.payment_intent &&
                              typeof latestInvoice.payment_intent !== 'string'
                            ) {
                              paymentIntentStatus =
                                latestInvoice.payment_intent.status;
                            }
                          }
                        }

                        if (invoiceStatus === 'open') {
                          if (
                            paymentIntentStatus === 'requires_payment_method'
                          ) {
                            // REQUIRES PAYMENT METHOD

                                setContent({
                                  ...content,
                                  processing: false,
                                  title: errorPaymentFail.title,
                                  summary: errorPaymentFail.summary,
                                  description:
                                    errorPaymentFail.description
                                      .childMarkdownRemark.rawMarkdownBody,
                                  callToAction: <CheckoutButton />,
                                });
                              } else if (
                                paymentIntentStatus === 'requires_action'
                              ) {
                                setContent({
                                  ...content,
                                  processing: false,
                                  title: errorRequiresAction.title,
                                  summary: errorRequiresAction.summary,
                                  description:
                                    errorRequiresAction.description
                                      .childMarkdownRemark.rawMarkdownBody,
                                  callToAction: (
                                    <>
                                      <Elements stripe={stripePromise}>
                                        <RequiresAction
                                          clientSecret={
                                            latestInvoice &&
                                            typeof latestInvoice !== 'string' &&
                                            latestInvoice.payment_intent &&
                                            latestInvoice.payment_intent
                                              .client_secret
                                          }
                                        />
                                      </Elements>
                                      <CheckoutButton />
                                      {content.callToAction}
                                    </>
                                  ),
                                });
                              } else {
                                Sentry.captureException(
                                  new Error(
                                    `invoiceStatus not open and paymentIntentStatus not requires_action (uid=${uid},checkoutId=${checkoutId})`,
                                  ),
                                );
                                isError();
                              }
                            }
                            break;

                      case 'past_due':
                        // this is a case where someone updates their account with a payment that fails
                        // need to confirm payment
                        // Can occur on an account update where payment method fails...
                        // direct them to the invoices page

                        setContent({
                          ...content,
                          processing: false,
                          title: errorPastDue.title,
                          summary: errorPastDue.summary,
                          description:
                            errorPastDue.description.childMarkdownRemark
                              .rawMarkdownBody,
                          callToAction: (
                            <>
                              <Button
                                onClick={(event) => {
                                  event.preventDefault();
                                  navigate(`${rootPath}account/order-history`);
                                }}
                                width="100%">
                                {seeYourInvoicesLabel}
                              </Button>
                            </>
                          ),
                        });

                        break;

                      // Commenting out 'failed' as this is no longer a
                      // valid subscription status.
                      // case 'failed':
                      //   // FAILED
                      //   setContent({
                      //     ...content,
                      //     processing: false,
                      //     title: errorSystemFail.title,
                      //     summary: errorSystemFail.summary,
                      //     description: `${errorSystemFail.description.childMarkdownRemark.rawMarkdownBody}`,
                      //     callToAction: (
                      //       <>
                      //         <CheckoutButton />
                      //         {content.callToAction}
                      //       </>
                      //     ),
                      //   });

                          //   break;
                          default:
                            Sentry.captureException(
                              new Error(
                                `subscription.status is not [trialing, active, incomplete, past_due] (uid=${uid},checkoutId=${checkoutId},status=${subscription.status})`,
                              ),
                            );
                            isError();
                            break;
                        }
                      }
                    } else if (!isCancelled) {
                      // NO CONFIRMATION RECORD
                      // There is no confirmation record here

                      setContent({
                        ...content,
                        processing: false,
                        title: errorNoConfirmationRecord.title,
                        summary: errorNoConfirmationRecord.summary,
                        description:
                          errorNoConfirmationRecord.description
                            .childMarkdownRemark.rawMarkdownBody,
                        callToAction: (
                          <>
                            <CheckoutButton />
                            {content.callToAction}
                          </>
                        ),
                      });
                    }
                  } catch (error) {
                    Sentry.captureException(error);
                    console.log(error);
                    if (!isCancelled) {
                      isError();
                    }
                  }
                }
              } else if (!isCancelled) {
                // NO RECORD FOUND
                // nothing in subscription table
                // Likley that processing never got triggered or bad URL
                setContent({
                  ...content,
                  processing: false,
                  title: errorOrderNotFound.title,
                  summary: errorOrderNotFound.summary,
                  description:
                    errorOrderNotFound.description.childMarkdownRemark
                      .rawMarkdownBody,
                  callToAction: (
                    <>
                      <CheckoutButton />
                      {content.callToAction}
                    </>
                  ),
                });
              }
            },
            (err: any) => {
              Sentry.captureException(err);
              // something went wrong when attempting to retrieve our stored record
              // Could be a permissions issue, someone attempting to look at a confirmation not assigned to their account
              if (!isCancelled) {
                isError();
              }
            },
          );
        // detach listener on page unmount.
        return () => {
          unsub();
          isCancelled = true;
        };
      } catch (error) {
        Sentry.captureException(error);
        if (!isCancelled) {
          isError();
        }
      }

    return () => {
      isCancelled = true;
    };
  }, []);

  // Render portion
  // System is still retrieving record until it has received checkout data and a matching confirmation record
  return (
    <Layout
      colorTheme="light"
      title={`${metaTitle} - CloudFit`}
      description={metaDescription}
      noIndex>
      <LoadingOverlay
        visible={content.processing}
        loadingText={processingLabel}
      />

      <Section.Container
        backgroundColor="light"
        id="profile details"
        mt={[4, 3]}>
        <Flex
          flexWrap="nowrap"
          flexDirection="column"
          width="100%"
          alignItems="center">
          <Box sx={{ visibility: content.processing ? 'hidden' : 'visible' }}>
            <Flex my={[5, 6]} flexDirection="column" alignItems="center">
              <Flex mx={[3, 5, 6]} flexDirection="column" alignItems="center">
                <TextLibrary.H1>{content.title}</TextLibrary.H1>
                <TextLibrary.H4>{content.summary}</TextLibrary.H4>
                <TextLibrary.Paragraph as="div" color="greyHigh">
                  <ReactMarkdown components={markdownRenderer}>
                    {content.description}
                  </ReactMarkdown>
                </TextLibrary.Paragraph>
              </Flex>

              <Flex flexDirection="column" alignItems="center" minWidth="220px">
                {content.callToAction}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Section.Container>
    </Layout>
  );
};

Confirmation.propTypes = {
  uri: PropTypes.string,
  checkoutId: PropTypes.string,
};

export default Confirmation;
