import { useLocation } from '@reach/router';
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  User as FirebaseUser,
  GoogleAuthProvider,
  onAuthStateChanged,
} from 'firebase/auth';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { logout } from '../utils/Auth';
import { AppleProviderId, firebaseAuth } from '../utils/Firebase';
import { LocaleContext } from './ContextProvider';

interface AuthContextInterface {
  authUser: FirebaseUser | null | undefined;
  providerId: string | null | undefined;
  providerIds: (string | undefined)[];
  updateAuthContext: ({
    authContext,
  }: {
    authContext: AuthContextInterface;
  }) => void;
}

export const AuthContext = React.createContext<AuthContextInterface>({
  authUser: null,
  providerId: null,
  providerIds: [],
  updateAuthContext: () => {
    // Empty function
  },
});

interface PrivateRouteInterface {
  component: any;
  verifiedEmailRequired?: boolean;
  path?: string;
  location: any;
}

const PrivateRoute = ({
  component: Component,
  verifiedEmailRequired = false,
  path,
  ...rest
}: PrivateRouteInterface): JSX.Element => {
  const pageLocation = useLocation();
  const localeData = useContext(LocaleContext);
  const { rootPath } = localeData;
  const redirectUrl = pageLocation.pathname;
  const [authContext, setAuthContext] = useState<AuthContextInterface>({
    authUser: null,
    providerId: null,
    providerIds: [], // Add the missing providerIds property
    updateAuthContext: ({
      authContext,
    }: {
      authContext: AuthContextInterface;
    }) => {
      setAuthContext(authContext);
    },
  });

  // Retrieve the user record from our DB and setup a change listener
  useEffect(() => {
    let isCancelled = false;

    try {
      const unsubscribe = onAuthStateChanged(firebaseAuth, (firebaseUser) => {
        if (!isCancelled) {
          if (firebaseUser) {
            // Get the first supported matching provider id using the hierarchy:
            // 1. Email 2. Gmail 3. Facebook 4. Apple

            const providerIds = firebaseUser.providerData.map((provider) => {
              return provider?.providerId;
            });

            const providerId = firebaseUser.providerData.find(
              (provider) =>
                provider &&
                (provider.providerId === EmailAuthProvider.PROVIDER_ID ||
                  provider.providerId === GoogleAuthProvider.PROVIDER_ID ||
                  provider.providerId === FacebookAuthProvider.PROVIDER_ID ||
                  provider.providerId === AppleProviderId),
            )?.providerId;

            setAuthContext({
              ...authContext,
              authUser: firebaseUser,
              providerId: providerId,
              providerIds: providerIds,
            });

            // If we’re checking that the user's email needs to be verifed, redirect to the email verification gate.
            if (verifiedEmailRequired && !firebaseUser.emailVerified) {
              navigate(
                `${rootPath}account/verify-email${pageLocation.search}`,
                {
                  replace: true,
                  state: { redirectUrl },
                },
              );
            }
          } else {
            // If we’re not logged in, redirect to the home page.
            // Make sure we clear any leftover cookie data
            logout();

            navigate(`${rootPath}login${pageLocation.search}`, {
              replace: true,
              state: { redirectUrl },
            });
          }
        }
      });
      // const unsubscribe2 = firebase
      //   .auth()
      //   .onAuthStateChanged((firebaseUser) => {
      //     if (!isCancelled) {
      //       if (firebaseUser) {
      //         // Get the first supported matching provider id using the hierarchy:
      //         // 1. Email 2. Gmail 3. Facebook 4. Apple

      //         const providerIds = firebaseUser.providerData.map(
      //           (provider) => {
      //             return provider?.providerId;
      //           },
      //         );

      //         const providerId = firebaseUser.providerData.find(
      //           (provider) =>
      //             provider &&
      //             (provider.providerId ===
      //               firebase.auth.EmailAuthProvider.PROVIDER_ID ||
      //               provider.providerId ===
      //                 firebase.auth.GoogleAuthProvider.PROVIDER_ID ||
      //               provider.providerId ===
      //                 firebase.auth.FacebookAuthProvider.PROVIDER_ID ||
      //               provider.providerId === 'apple.com'),
      //         )?.providerId;

      //         setAuthContext({
      //           ...authContext,
      //           authUser: firebaseUser,
      //           providerId: providerId,
      //           providerIds: providerIds,
      //         });

      //         // If we’re checking that the user's email needs to be verifed, redirect to the email verification gate.
      //         if (verifiedEmailRequired && !firebaseUser.emailVerified) {
      //           navigate(
      //             `${rootPath}account/verify-email${pageLocation.search}`,
      //             {
      //               replace: true,
      //               state: { redirectUrl },
      //             },
      //           );
      //         }
      //       } else {
      //         // If we’re not logged in, redirect to the home page.
      //         // Make sure we clear any leftover cookie data
      //         logout(firebase);

      //         navigate(`${rootPath}login${pageLocation.search}`, {
      //           replace: true,
      //           state: { redirectUrl },
      //         });
      //       }
      //     }
      //   });

      // On unmount this will clear out the listener from memory
      // detach listener on unmount
      return () => {
        unsubscribe();
        isCancelled = true;
      };
    } catch (error) {
      if (!isCancelled) {
        console.error(error);
        // If we’re not logged in, redirect to the home page.
        navigate(`${rootPath}login${pageLocation.search}`, {
          replace: true,
          state: { redirectUrl },
        });
      }
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  // Component will always display to prevent React hydation re-renders
  // Make sure on private pages not to show sensitive info until verified
  return (
    <AuthContext.Provider value={authContext}>
      <Component path={path} {...rest} />
    </AuthContext.Provider>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default PrivateRoute;
